module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#fff","display":"standalone","icon":"src/images/lobster512.png","include_favicon":false,"name":"Demo Lawn Cumberland","short_name":"Demo Lawn Cumberland","start_url":"/","theme_color_in_head":false,"theme_color":"#6b37bf","legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"a942bdea135c4a90ac6f05e3648914fb"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://cumberland-lawn.lobstermarketing.com","noQueryString":true},
    },{
      plugin: require('../../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Home","exclude":["/dev-404-page/","/404/","/404.html","/offline-plugin-app-shell-fallback/"],"crumbLabelUpdates":[{"pathname":"/blog","crumbLabel":"Blog"},{"pathname":"/photo-gallery","crumbLabel":"Photo Gallery"},{"pathname":"/where-we-service","crumbLabel":"Where We Service"}]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-tagmanager-timeout/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PHR5M4C","timeout":5000,"includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"page-change"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-70},
    },{
      plugin: require('../../gatsby-theme-lawn-cumberland/gatsby-browser.js'),
      options: {"plugins":[],"projectId":"5dfe5f94-fd72-00cc-e6e3-7ad5baf20fae","authorizationKey":"ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogIjEzZWRiYWI2MGNmYjRjZjRhNDEwMmQ3N2E3NzhmNGE0IiwNCiAgImlhdCI6ICIxNjM1MjcxOTcyIiwNCiAgImV4cCI6ICIxOTgwODcxOTcyIiwNCiAgInByb2plY3RfaWQiOiAiNWRmZTVmOTRmZDcyMDBjY2U2ZTM3YWQ1YmFmMjBmYWUiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.oUttVhwnyueLUnTMolaXD_n9BRpJI2XCT5KKjwTTk1U","usePreviewUrl":"false","whereWeServicePathName":"where-we-service","whereWeServiceBreadcrumb":"Where We Service","wWSActive":"0","siteUrl":"https://cumberland-lawn.lobstermarketing.com","title":"Demo Lawn Cumberland","description":"Demo Lawn Cumberland","domainVerification":"meta-tag","gtmId":"GTM-PHR5M4C","compassID":"8178cfb10750a2a33c491689ef9ff80ab3043c2dea7a983121c513d31d0a3aa9","recaptchaSiteKey":"6LekvNohAAAAAPcynlLGnJgkyWKJx0YVnwJi3zpu","recaptchaSecretKey":"6LekvNohAAAAAAUnsLp-e0rFcRi0sBsq_paJ5q5T","photoGalleryPathName":"photo-gallery","photoGalleryBreadcrumb":"Photo Gallery","photoGalleryActive":"0"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
